import React from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import {Link, graphql} from 'gatsby'
import HeroBanner from '../../components/Hero'

const hero_settings = {
  size: 'is-medium',
  particle: false,
  images: '',
  css: '#ffafbd,#ffc3a0~#2193b0,#6dd5ed',
  font_color: '#fff',
}

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: { siteMetadata: { title } },
  },
}) => (
  <div>
    <Helmet title={`Tags | ${title}`} />

    <HeroBanner
      title={'Tags'}
      subtitle={''}
      hero_settings={hero_settings}
    />

    <section className='section is-feature-grey edge--top'>
      <div className='container content'>
        <ul className='taglist'>
          {group.map(tag => (
            <li key={tag.fieldValue}>
              <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                <div className='tags are-large has-addons'>
                  <span className='tag is-link is-light'>{tag.fieldValue}</span>
                  <span className='tag is-primary'>{tag.totalCount}</span>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  </div>
)

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000, filter: {frontmatter: {templateKey: {ne: "examples-residential-style-1"}}}) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
